/* Container for the grid */
.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
    justify-items: center;
  }
  
  /* Individual service item */
  .service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
    
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .service-item:hover {
    transform: scale(1.05);
  }
  
  .service-icon {
    font-size: 40px;
    color: #0056b3;
    margin-bottom: 10px;
  }

  .service-icon img{
    height: 50px;
  }
  
  .service-name {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    text-align: center;
  }
  
  /* Media Query for responsiveness */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .services-grid {
      grid-template-columns: 1fr;
    }
  }