.prop-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
  }
  
  .prop-image-column {
    flex: 1;
    padding-right: 20px;
  }
  
  .prop-image-column img {
    /* height: 100%; */
    width: 100%;
    height: 300px;
    border-radius: 10px; /* Optional for rounded corners */
  }
  
  .prop-content-column {
    flex: 1;
  }
  
  @media (max-width: 768px) {
    .prop-container {
      flex-direction: column;
      align-items: center;
    }
  
    .prop-image-column, .prop-content-column {
      padding: 10px;
    }
  
    .prop-image-column {
      padding-bottom: 20px;
    }
  }